/**
 * Utility function for checking expiration month and year
 *
 * @param today - a date object
 * @param expYear
 * @param expMonth
 * @returns {boolean}
 */
export default function(today, expYear, expMonth) {

    if( !today )
        return false

    if( !expYear )
        return false

    if( !expMonth )
        return false

    if( Number(expYear) > today.getFullYear() ) {
        return true
    } else if( Number(expYear) === today.getFullYear() ) {
        if( Number(expMonth) >= (today.getMonth()+1) ) {
            return true;
        }
    }

    return false;
}
